@media screen and (max-width: 1024px) and (min-width: 993px) {
  .profile-outer {
    display: block !important;
    text-align: center;
  }

  .profile-pic {
    margin: auto;
  }
}

@media screen and (max-width: 767px) {
  body {
    font-size: 14px;
  }

  img {
    max-width: 100%;
  }

  .heading-logo {
    max-width: 100%;
  }

  .landing-logo img {
    max-width: 100%;
  }

  .landing-logo {
    padding: 60px 0 30px;
  }

  .about-section {
    margin: 60px 0 40px;
  }

  .section-heading h1 {
    font-size: 25px;
  }

  .toggle-btn {
    display: block;
    position: absolute;
    right: 5px;
    top: 20px;
  }

  aside {
    width: 302px;
    transform: translateX(-100%);
    transition: all ease 0.5s;
    position: fixed;
    z-index: 10;
    top: 60px;
  }

  .right-panel {
    width: 100%;
    padding: 17px;
  }

  .wrapper.active aside {
    transform: translateX(0);
  }

  .page-title h2 {
    font-size: calc(1.325rem + 0.9vw);
    font-weight: 500;
  }

  .btn-default {
    font-size: 16px;
    height: 40px;
    line-height: 40px;
  }

  .item-list .item {
    width: 100%;
    overflow: auto;
  }

  .quantity .number {
    display: flex;
    align-items: center;
  }

  .icon-delete {
    right: 0;
    margin-right: -32px;
    top: 24px;
  }

  .addProductBtn {
    margin-top: 0px;
    font-size: 15px;
  }

  .cartlist-outer {
    padding: 0px;
  }

  .saveWantList {
    justify-content: center;
  }

  .nav-link:hover {
    background-color: inherit;
  }

  .varient-table th,
  .varient-table td {
    padding: 5px;
    font-size: 14px;
  }

  .redeem-sec {
    justify-content: space-between;
  }
  .havelist-card .MuiAccordionSummary-content .MuiTypography-body1 {
    display: flex;
    flex-direction: column;
  }
  .havelist-card .MuiAccordionSummary-content .MuiTypography-body1 .totalprice {
    margin: 5px 0 0 8px;
  }
  .total-order-price {
    float: none;
    margin-top: 0;
  }
  .MuiAccordionDetails-root {
    margin-top: 10px;
  }
  .right-panel .MuiTypography-body1 .heading h5 {
    font-size: 15px;
  }
  .seller_detail tr td {
    vertical-align: middle;
  }
}

/* Phone screen */
@media screen and (max-width: 500px) {
  .totalprice {
    margin-top: -23px;
  }
  .swal2-image {
    width: 300px !important;
    height: 300px !important;
  }

  .tags-input-profile {
    padding: 10px 0px;
  }
  .item-subtotal {
    display: block;
  }

  .saveWantList {
    margin-left: 0;
  }

  .item-subprice {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 19px;
  }

  .weightPrice {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 7px 0;
  }

  .product-list {
    display: block;
  }

  /* .total-product {
    display: flex;
    justify-content: space-between;
  } */

  .saveWantList {
    margin-right: 0;
  }

  .product-quantity input {
    max-width: 100%;
  }

  .cart-head {
    display: none;
    height: 32px;
  }

  .checkout-btns {
    display: block;
  }

  .check-out-btn {
    margin-bottom: 5px;
  }

  .basket-table {
    padding: 0;
  }

  /* .table-borderless td{padding:3px} */
  .card-cart {
    margin: 0;
    padding: 0;
  }

  .wantlist-table {
    width: 100% !important;
  }

  .styleButton {
    margin-left: 10px !important;
  }

  .seller {
    margin: inherit;
  }

  .haveTable {
    display: inline-flex;
  }

  .havetablelist {
    display: block;
  }

  .table-responsive {
    font-size: 12px;
  }

  .havetablelist td .btn {
    font-size: 12px !important;
  }

  .detail-btn {
    padding: 12px 24px !important;
    margin-top: 15px;
  }

  .product-name {
    word-break: break-all;
    width: 40px;
  }

  .edit-delete-btn {
    display: flex !important;
    justify-content: end;
  }

  .cart-btn {
    width: 30px;
  }

  .MuiAccordionSummary-root {
    padding: inherit;
  }

  .table-name {
    width: 40%;
  }

  .MuiIconButton-root {
    padding: 4px !important;
  }

  .card-body {
    padding: 11px !important;
  }

  .havetablehead th {
    padding: 10px 18px;
  }

  .searchTerm {
    padding: 17px 7px;
  }

  .thankYouPageCard {
    padding: 60px 20px;
    margin: 54px auto;
  }

  .thankYouPageCard h3 {
    margin-top: 20px;
  }

  .subitems th {
    margin: 10px 7px;
  }

  .basket-table {
    padding: 5px !important;
  }

  .number {
    display: block !important;
  }

  .quantity-box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
  }

  a:hover {
    background-color: inherit;
  }

  .number-quantity {
    display: flex !important;
  }

  .buyer-want-list td {
    padding: 4px !important;
  }

  .number-box {
    display: flex !important;
  }

  .number-quantity .plus {
    line-height: 14px;
    padding-left: 0px;
  }

  .add-product-number {
    display: flex !important;
  }

  .redeem-history h3 {
    text-align: left;
  }

  .redeem-sec .have-qr {
    margin-left: 0px !important;
  }

  .havelist-card .MuiCollapse-wrapper .MuiAccordionDetails-root {
    padding: 8px 8px;
  }

  .MuiTypography-body1 td,
  .MuiTypography-body1 th {
    padding: 7px 2px;
  }

  .product-rules div label {
    min-width: inherit;
  }

  .input-text-box {
    margin-left: inherit;
  }
  .varient-table .itemName {
    min-width: 165px;
  }
}
@media (max-width: 767px) {
  .react-datepicker-wrapper {
    width: 100% !important;
  }
  .cart-head {
    display: none;
  }
}
@media screen and (max-width: 500px) {
  .product-rules div label {
    min-width: 100%;
  }
  .input-text-box {
    min-width: 100%;
  }
  .productitem {
    padding: 15px 10px 0;
  }
  .list-row .col-6 h2 {
    margin: 0;
  }
  .list-row .col-6 button {
    margin: 0;
  }
  .list-row {
    align-items: center;
  }
  .order-table > tbody > tr:nth-child(odd) td {
    background: inherit;
  }
  .headerSearch {
    margin-top: 15px;
  }

  .css-1licwwv {
    height: auto;
  }
}

@media screen and (max-width: 800px) {
  .cartlist-outer .total-price.cart-delete-btn {
    padding: 5px 10px 0;
    margin: 10px 0px 0;
  }
  .cartlist-outer .total-price.cart-delete-btn:before {
    display: none;
  }
  .cart-delete-btn button {
    background-color: #3598dc;
    padding: 5px;
    display: block;
    width: 100%;
    border-radius: 20px;
  }
  .cart-delete-btn .action-btn svg path {
    font-size: 16px;
    fill: white !important;
    border-radius: 5px;
  }
  .basketbtn {
    display: flex;
    gap: 15px;
  }
  table.order-table {
    width: 800px;
  }
}

@media screen and (max-width: 320px) {
  .quantity input {
    max-width: 76%;
  }
}

/* ipad air screen */
@media screen and (max-width: 900px) and (min-width: 500px) {
  .submit-btn-style {
    border-radius: 20px !important;
    font-size: 15px !important;
  }
  .wantlist-table {
    width: 100% !important;
  }

  .thankYouPageCard h3 {
    margin-top: 20px;
  }

  .thankYouPageCard {
    text-align: center;
    padding: 60px 20px;
    margin: 120px auto;
  }

  .a:hover {
    background-color: inherit;
  }

  .redeem-sec {
    justify-content: space-between;
  }
}

@media only screen and (max-width: 460px) {
  .submit-btn-style {
    border-radius: 20px !important;
    font-size: 15px !important;
  }
  .redeem-history h3 {
    text-align: left;
  }
  .input-fieldset {
    display: block;
  }

  .page-title h2 span {
    font-size: inherit;
  }
  .product-quantity {
    display: block;
  }
  .quantity-cart {
    display: block;
  }
  .description-cart {
    display: none;
  }
  .havelisttable tr {
    vertical-align: middle;
  }
  .have-card .page-title h2 {
    margin: 0;
  }
  .MuiAccordionSummary-root {
    align-items: center !important;
  }
  .cart-btn {
    margin-left: auto;
  }
  .cart-btn img {
    min-width: 30px;
    height: 30px;
    margin-top: 10px;
  }
  .cart-bubble {
    top: -5px;
  }
  .varient-table tr th,
  .varient-table tr td {
    text-align: center;
  }

  .new-tag {
    margin-top: 7px;
    overflow: visible;
    height: inherit;
  }
  .new-tag {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    overflow-x: auto;
  }
  .tag-close-icon {
    width: 16px !important;
  }
  .cart-value {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
  }
  .cart-delete-btn {
    justify-content: center;
    padding: 5px 0px;
    display: block;
    text-align: center;
  }
  .seller-listing-p {
    justify-content: end;
  }
  .searchTerm {
    width: 100% !important;
  }
  .cart-total-weight {
    margin-top: 25px;
  }
  .quantity-alert {
    left: 3%;
    width: 100% !important;
  }
  .quantity-cart {
    margin-right: 0;
  }
  .atlas-create-list {
    position: relative;
  }
  .productitem .varient-table {
    padding-bottom: 20px;
  }
  .col-quantitybtn button {
    min-width: 60px;
  }
}

@media only screen and (max-width: 900px) {
  .submit-btn-style {
    border-radius: 20px !important;
    font-size: 15px !important;
  }
  .descriptions {
    width: 100%;
  }
  .itemName strong {
    width: 100%;
  }
  .varient-table {
    padding-bottom: 0px;
  }
  .createBasket-btn {
    position: relative;
  }
  .quantity-alert {
    position: absolute;
    left: 35%;
  }
  .basket-view-lg {
    display: flex;
    gap: 12px;
  }
  .cart-btn {
    width: auto;
  }
  .variant-des-table {
    height: auto !important;
  }
}

@media only screen and (max-width: 900px) {
  .quantity-alert {
    left: 50%;
    transform: translateX(-50%);
    font-size: 10px !important;
    font-weight: 500;
  }
  .submit-btn-style {
    border-radius: 20px !important;
    font-size: 15px !important;
  }
  .css-1licwwv {
    height: auto !important;
  }
}
@media (max-width: 768px) {
  .modal-footer .cart-btn {
    width: 100px;
    text-align: center;
    min-height: 39px;
    display: inline-flex !important;
    align-items: center;
  }
  .submit-btn-style {
    border-radius: 20px !important;
    font-size: 15px !important;
  }
  .cart-btn {
    width: auto;
  }
  .searchTerm {
    width: 93%;
  }

  .activation-btn .btn-transparent[disabled] {
    opacity: 0.5;
  }

  .activation-btn .btn-transparent {
    border: none;
    background: none;
    color: #212121;
  }
  .cartlist-outer .cart-product-list > div:before {
    content: attr(data-cell);
    display: block;
    color: #212121;
    font-size: 16px;
    font-weight: bold;
  }
  .cartlist-outer .total-price {
    margin: 10px 0 0px;
  }
  .cartlist-outer .item-subprice .total-price {
    margin: 0px 0 0px;
  }
}

@media (min-width: 1200px) {
  .submit-btn-style {
    border-radius: 20px !important;
    font-size: 15px !important;
  }
  .basket-view-lg {
    display: flex;
  }
  .cart-btn {
    width: auto;
  }
  .searchButtonflex {
    margin-right: 10px !important;
  }
}

.submit-btn-style {
  border-radius: 20px !important;
  font-size: 15px !important;
}

@media only screen and (max-width: 1100px) {
  .order-table > tbody > tr:nth-child(odd) td {
    background: inherit;
  }
  .submit-btn-style {
    border-radius: 20px !important;
    font-size: 15px !important;
  }
}
