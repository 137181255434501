@import url(paysah.css);

body {
  background-color: #f5f5f5;
  font-family: "Montserrat", sans-serif;
}

a {
  transition: all ease 0.5s;
}
.css-bthbmc-MuiPaper-root-MuiAlert-root {
  font-family: "Montserrat", sans-serif !important;
}
.css-sghohy-MuiButtonBase-root-MuiButton-root {
  font-family: "Montserrat", sans-serif !important;
}

img {
  max-width: 100%;
}

.btn-check:focus + .btn-dark,
.btn-dark:focus {
  outline: none;
  box-shadow: none;
}
.cart-btn .spinner-border {
  width: 1.5rem;
  height: 1.5rem;
}

.wrapper {
  position: relative;
  z-index: 10;
  background-color: #f5f5f5;
  margin-bottom: 34px;
}
.scrollable-container {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
}

.register-form .btn {
  border-radius: 30px;
  font-size: 16px;
  height: 44px;
  background-color: #3498db;
  border: solid 1px #3498db;
  overflow: hidden;
  line-height: 45px;
  padding: 0;
}
.register-form .btn:hover .register-form .btn:focus {
}
.dropdown .btn:hover .dropdown .btn:focus {
  border: solid 1px #dbdbdb;
}
.register-form .btn:hover {
  background: none;
  border: solid 1px #dbdbdb;
  color: #000;
}
.btn-success:hover .btn-success:active .btn-success:focus {
  box-shadow: 0px 0px;
}
.btn-blue {
  background-color: #3498db !important;
}
.btn-blue:hover {
  background-color: #fff !important;
  border: 2px solid #000;
  color: #000;
}
.submit-btn-style {
  border-radius: 20px !important;
}
.quantity-alert {
  white-space: nowrap;
}

.register-form .btn.btn-bordered {
  background-color: transparent;
  border: solid 1px #000;
  color: #000;
}
.register-form .btn.btn-bordered:hover {
  background-color: transparent;
  border: solid 1px #000;
  color: #000;
}
.pro-title {
  font-family: "Montserrat", sans-serif !important;
  margin-left: 10px;
  margin-top: 11px;
}

.mobile-logo {
  display: none;
}

.navbar {
  /* background: #fff; */
  min-height: 61px;
  display: flex;
  align-items: center;
}

.top-action-bar {
  align-items: center;
  background: #fff;
  display: flex;
  min-height: 61px;
}

.navbar .nav-link {
  font-size: 18px;
  color: #000;
  text-transform: uppercase;
}

.navbar-nav li {
  margin-right: 25px;
}

.navbar .nav li {
  margin-right: 25px;
}

.btn-outline-secondary {
  border: solid 3px #000;
  font-size: 18px;
  color: #fff;
  height: 40px;
}

.btn-outline-secondary:hover {
  background: #000;
  color: #fff;
  border: none;
}

.btn.btn-transparent {
  background-color: transparent;
  color: #000;
}
.btn.btn-transparent:hover {
  color: #000;
}

.landing-logo {
  padding: 170px 0 100px;
}

.landing-logo a:hover {
  background: none;
}
.pro-subheading {
  font-family: "Montserrat", sans-serif !important;
}
.ref-name {
  display: block !important;
}
.main-box tbody,
td,
tfoot,
th,
thead,
tr {
  border: none !important;
}
.category-btns {
  height: 30px;
  font-size: 14px !important;
  border-radius: 20px !important;
  /* padding: 0px !important; */
}
.category-btns:hover {
  background: #c8c8c8 !important;
  color: #000 !important;
}

.multiple-heading .pro-subheading {
  margin-left: 0px;
}
.qty-button .quantity {
  margin-right: 10px;
}

.about-section {
  margin: 200px 0 100px;
}

.about-section h2 {
  font-weight: 600;
  font-size: 40px;
}

.how-it-works-section {
  margin: 200px 0 100px;
}

.pricing-section {
  margin: 200px 0 100px;
}

.footer-section {
  background-color: #222;
  text-align: center;
  text-decoration: none;
  color: white;
  padding: 5px 0px;
  margin: 0;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
}

.footer-section p {
  margin: 0;
}

.end-footer a {
  color: #fff;
  font-weight: 600;
  text-decoration: none;
}

.register-form {
  max-width: 650px;
  margin: 0 auto;
}

.register-form .input-group button[type="button"] {
  background: #fff;
  border-radius: 5px;
  border: solid 1px #dbdbdb;
  padding: 0 10px;
}

/* .register-form input{height:50px} */
.full-width {
  max-width: 1000px;
}
.stleref {
  font-size: 14px !important;
  font-family: "Montserrat", sans-serif !important;
}
.heading-logo {
  max-width: 159px;
  margin: 0 auto 60px;
}

.section-heading h1 {
  font-size: 45px;
  margin-bottom: 40px;
}

.form-control {
  border-radius: 5px;
  border: solid 1px #dbdbdb;
  padding: 8px 12px;
  font-size: 15px;
}

.seller-form-control {
  border-radius: 0;
  border: solid 1px #dbdbdb;
  padding: 8px 12px;
  font-size: 15px;
  margin-left: auto;
  margin-right: auto;
}

.btn-default {
  border-radius: 0;
  font-size: 18px;
  height: 45px;
  line-height: 45px;
  padding: 0;
}

.redeem-history .qrcode {
  text-align: left;
  margin: 15px 0;
  display: flex;
}

.qrcode-option {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-left: 10px;
}

.register-link a {
  color: #000;
  text-decoration: none;
  font-weight: 600;
}

aside {
  background: #fff;
  width: 62px;
  overflow: hidden;
  position: sticky;
  left: 0;
  bottom: 0;
  top: 0;
  margin-top: 2px;
  min-height: calc(100vh - 112px);
  transition: all ease 0.5s;
}

aside:hover {
  width: 302px;
  overflow: hidden;
}

aside li a i {
  font-size: 20px;
  padding-right: 0;
}

aside li a span {
  white-space: nowrap;
}

aside ul {
  margin: 0;
  padding: 30px 5px;
}

aside ul li {
  list-style: none;
  margin-bottom: 5px;
  border-bottom: solid 1px #e9e9e9;
}

aside ul li a {
  color: #36454f;
  text-decoration: none;
  padding: 15px;
  display: flex;
  flex-wrap: nowrap;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  font-weight: 600;
  position: relative;
  font-size: 16px;
}

aside ul li.active a,
aside ul li a:hover {
  background: #fff;
  color: #000;
}

aside ul li a i {
  padding-right: 20px;
  font-size: 20px;
}

aside ul li.active a svg path,
aside ul li a:hover svg path {
  fill: #000;
}

aside ul li a svg {
  font-size: 20px;
  fill: #36454f;
  width: 25px;
  height: 25px;
  min-width: 25px;
  margin-right: 20px;
}
aside:hover ul li a:after {
  content: "";
  background: url(../images/icon-right.svg) right top no-repeat;
  width: 17px;
  height: 17px;
  display: block;
  position: absolute;
  background-size: 100%;
  right: -5px;
  top: 17px;
}

aside ul li a:hover svg {
  fill: #000;
}

.right-panel {
  width: calc(100% - 58px);
  padding: 30px;
}
.right-panel h2 {
  text-align: center;
}

.card {
  border: none;
}

.page-title {
  margin-bottom: 20px;
}

.page-title h1 {
  font-weight: 600;
}

.page-title h2 {
  font-size: 19px;
  font-weight: 600;
}

.page-title h2 span {
  color: #fa0000;
  font-weight: 700;
}

.profile-pic {
  width: 115px;
  height: 115px;
  border-radius: 50%;
  overflow: hidden;
  min-width: 115px;
}

.toggle-btn {
  background: none;
  border: none;
  display: none;
  width: 32px;
  height: 32px;
}

.mr-2 {
  margin-right: 2rem;
}

.icon-bar {
  display: block;
  width: 25px;
  height: 3px;
  background: #000;
  transition: 0.3s;
}

.icon-bar + .icon-bar {
  margin-top: 6px;
}

.wrapper.active .toggle-btn .icon-bar:nth-of-type(1) {
  transform: translate3d(0, 8px, 0) rotate(45deg);
}

.wrapper.active .toggle-btn .icon-bar:nth-of-type(2) {
  opacity: 0;
}

.wrapper.active .toggle-btn .icon-bar:nth-of-type(3) {
  transform: translate3d(0, -10px, 0) rotate(-45deg);
}

.table-borderless th {
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  padding: 10px;
  font-weight: 600;
  text-align: left;
  color: #000;
}

.table-borderless th:first-child {
  text-align: left;
}

.table-borderless td {
  padding: 10px;
  text-align: left;
}

.havelisttable tr {
  vertical-align: middle;
}

.text-red {
  color: red;
}

.add-list {
  background: #fff;
  border-radius: 8px;
  display: flex;
  padding: 5px;
}

.add-list input {
  padding: 8px;
  width: 100%;
  border: none;
}

.add-list button {
  background: #000;
  border-radius: 8px;
  color: #fff;
  padding: 6px 15px;
  min-width: 120px;
}

.item-list {
  margin-top: 24px;
}

.item-list .item {
  background: #fff;
  padding: 9px 15px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
  position: relative;
  overflow: auto;
}

.item-list .item h4 {
  font-size: 18px;
  margin: 0;
}

/* .minus,
.plus {
  width: 20px;
  height: 20px;
  background: none;
  border-radius: 50%;
  border: 1px solid #000;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  padding: 0px 0px;
  line-height: 13px;
} */
.quantity input {
  max-width: 30px;
  text-align: center;
  font-weight: 600;
  border: none;
  margin: 0 10px;
  height: 35px;
  background-color: inherit;
}

/* .quantity .plus {
  background: #000;
  color: #fff;
} */
.icon-delete {
  position: absolute;
  right: 0;
  margin-right: -50px;
  top: 25px;
}

.icon-delete button {
  border: none;
  background: none;
}

.mobile-user {
  display: none;
}

.profile-container {
  max-width: 1050px;
  margin: auto;
}

.profile-container .form-group {
  margin-bottom: 20px;
}

.icon-back {
  display: none;
}

.store-detail ul {
  margin: 0;
  padding: 0 0 0;
  list-style: none;
  position: relative;
}

.store-detail ul li {
  margin: 5px 0;
}

.store-detail ul li:before {
  content: "";
  height: 10px;
  width: 10px;
  background: #000;
  border-radius: 50%;
  display: inline-block;
  margin-right: 7px;
}

.store-detail ul:after {
  content: "";
  border: dashed 1px #000;
  position: absolute;
  left: 4px;
  top: 5px;
  bottom: 5px;
}

.item-list-icon {
  min-width: 30px;
}

.icon-takephoto {
  width: 35px;
  height: 35px;
  background: #fff;
  padding: 5px;
  border-radius: 50%;
  position: relative;
  bottom: 40px;
  z-index: 10;
  display: block;
  margin: auto;
  left: 45px;
}

.icon-takephoto img {
  width: 25px;
}

.item-list input {
  width: 100%;
  height: 40px;
  padding: 10px;
  /* border: solid 1px #dbdbdb; */
  min-width: 115px;
}

.item-list select {
  width: 100px;
  height: 40px;
  padding: 5px 10px;
  border: solid 1px #dbdbdb;
}

.item-list .form-controls {
  width: 200px;
  height: 40px;
  padding: 5px 10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.item-list table td {
  position: relative;
}

.loader-BG {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  z-index: 1;
  /* position: fixed;
  left: 0;
  top: 0;
  width: 100%; */
}
.loader-log {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100% !important;
  z-index: 1;
  position: relative;
}

.loader-text {
  margin-left: 10px; /* Adjust the value as needed */
}

.qrcode-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 136px);
}

.qrcode-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 136px);
}
.react-datepicker-wrapper {
  width: 0% !important;
}

.pickup-options {
  text-align: left;
}

.time-slot-button {
  overflow: auto;
  width: 100%;
}

.time-slot-button button {
  min-width: 40%;
  border-radius: 5px !important;
}

.time-slot-button .btn.btn-dark,
.time-slot-button .btn.btn-outline-primary:hover {
  background: #c8c8c8;
  color: #000;
}

.time-slot-button .btn.btn-outline-primary,
.time-slot-button .btn.btn-outline-primary:focus {
  color: #000;
  background: none;
}

.pickup-options .react-datepicker-wrapper {
  width: 100% !important;
}

.order-table {
  width: 100%;
}

.multiSelect {
  width: 227px;
}

.multiSelect input {
  margin-top: 30px;
}

.quantityInput {
  padding-bottom: 10px;
}

.sizeOption {
  padding-bottom: 10px;
}

.productList {
  float: right;
  margin-top: -36px;
  margin-right: 63px;
  font-size: larger;
}

.search {
  width: 100%;
  position: relative;
  display: flex;
  padding: 0px;
}

.search select {
  border: none;
  background: none;
  font-size: 14px;
}

.search select:focus,
.search input:focus {
  outline: none;
  border: none;
}

.searchTerm {
  width: 100%;
  padding: 0 15px;
  height: 45px;
  background: #fff;
  border: none;
  outline: none;
  box-shadow: none;
  border-radius: 10px;
  font-family: "Montserrat", sans-serif;
}

.searchTerm:focus {
  color: black;
}
input[type="search"].searchTerm::-webkit-search-cancel-button {
  display: none;
}

.searchButton {
  width: 40px;
  height: 36px;
  border: 1px solid black;
  background: black;
  text-align: center;
  color: #000;
  border-radius: 8px;
  cursor: pointer;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.searchButtonflex {
  width: 110px;
  height: 36px;
  border: 1px solid black;
  background: black;
  text-align: center;
  color: #000;
  border-radius: 8px;
  cursor: pointer;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.styleBtn {
  float: left !important;
}

.seller {
  margin-left: 40px;
}

.styleButton {
  margin-left: 20px;
}

.form-check {
  margin-top: 10px;
}

.styleSpan {
  display: block;
  margin-top: 12px;
  font-size: 14px;
  font-weight: 500;
}

.App {
  text-align: center;
}
input,
button,
select,
optgroup,
textarea {
  font-family: "Montserrat", sans-serif !important;
}
.css-6loa9u {
  font-family: "Montserrat", sans-serif !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.itemName {
  display: flex;
  align-items: start;
  top: 0;
  position: relative;
  max-width: 75%;
  justify-content: space-between;
}

.itemName strong {
  width: 70%;
}
.varient-table .itemName {
  font-weight: 600;
  margin-bottom: 0;
  padding-left: 5px;
  text-align: left;
}

.itemName input {
  margin: 0px 7px 0 0px;
}

.order-table > tbody > tr:nth-child(even) td {
  background: rgba(53, 152, 220, 0.08);
}

.order-table > tbody > tr:nth-child(odd) td {
  background: #fff;
}

.subitems th {
  background: #ebebeb;
  border: none;
  font-size: 14px;
}

.order-table > tbody > tr > td > .itemName {
  margin-bottom: 10px;
}

.subitems td,
.subitems th {
  font-size: 13px;
  padding: 10px 5px;
  color: rgb(61, 61, 61);
}

.subitems {
  position: relative;
  left: 0px;
  /* border-bottom: 1px solid #ccc; */
}

.action-button {
  background: #000;
  border-radius: 8px;
  padding: 8px 10px;
  font-size: 13px;
  color: #fff;
  border: none;
}

.action-button:disabled,
.StylePagination button:disabled {
  opacity: 0.6;
}

.sellername {
  display: block;
  font-size: 14px;
  text-align: left;
  line-height: 15px;
}

.StylePagination button {
  font-size: 14px;
  padding: 8px 15px;
  border: solid 1px #666;
  border-radius: 8px;
}

.productname span {
  display: block;
}

.cartlist-outer .item {
  align-items: center;
  height: auto;
}

.cartlist-outer .total-price {
  padding-top: 0;
  text-align: center;
}

.cartlist-outer li {
  list-style: none;
  border-bottom: solid 1px #f2f2f2;
  padding: 25px 0;
}

.cartlist-outer li .item {
  padding: 0px;
}

.cartlist-outer li .description {
  padding-top: 0px;
  min-width: 15%;
  margin-right: 0;
}

.cartlist-outer li .quantity {
  /* min-width: 15%; */
  text-align: center;
}

/* .cartlist-outer li .total-price {
  min-width: 15%;
} */

.cartlist-outer .total-price {
  /* display: block; */
  /* width: auto; */
  font-weight: 500;
}

.cartlist-outer .description span:last-child {
  color: #000;
}

.cartlist-outer li .total-weight {
  min-width: 350px;
  text-align: center;
}

.Toastify__toast-container--top-center {
  top: 4em !important;
}
.order-item {
  background: #fff;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 10px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 1rem;
}

.pagination button {
  width: 40px;
  height: 40px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 16px;
  margin: 0 10px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  background: transparent;
  color: #eee;
  border-color: #eee;
}

.pagination button.active {
  font-weight: 900;
  border-color: #101010;
  background: #ffe400;
  color: #101010;
}

.StylePagination {
  text-align: center;
  margin: 30px;
  font-size: 20px;
}

.right-panel .MuiTypography-body1 {
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  width: 100%;
  /* display: inline-table; */
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

.addProductBtn {
  padding: 6px 10px;
  background: #3598dc;
  border: none;
}
.dropdown {
  width: 30%;
}
.dropdown .dropdown-toggle {
  background-color: #fff;
  color: #000;
  border-radius: 5px;
  border: solid 1px #dbdbdb;
}

.productsearch {
  display: flex;
  gap: 15px;
}
.productsearch button.btn {
  width: auto;
  padding: 0px;
  min-width: 40px;
  height: 40px;
  margin-top: 0px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}
.productsearch.storesearch .resetButtonflex {
  margin-left: 10px;
}
.productsearch.storesearch {
  gap: 10px;
}
.productsearch button i::before {
  margin: 0;
}

.productsearch .search input,
.productsearch .search input:focus {
  border: solid 1px #e0e0e0;
  border-radius: 5px;
  height: 40px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
}
.multiplesearch button.searchButtonflex.btn.btn-dark {
  margin: 0;
}

.headerSearch {
  margin-bottom: 20px;
}

.headerSearch h2 {
  font-size: 18px;
}

.headerSearch .row {
  align-items: flex-end;
  justify-content: space-between;
}

.searchButtonflex {
  padding: 6px 20px;
  background: #3598dc;
}

.btn {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  border: none;
  background: #3598dc;
  border-radius: 5px;
  font-weight: 500;
}
.border-round {
  border-radius: 20px !important;
}
.style-table th:first-child,
.style-table td:first-child {
  text-align: left;
}

.action-btn {
  border: none;
  background: none;
}

.style-table td:last-child {
  text-align: right;
}

.varient-table {
  margin: 0 -10px 0;
  padding: 0;
  font-size: 14px;
  margin-bottom: 5px;
  border-radius: 10px;
}

.col-proprice {
  text-align: left !important;
  font-weight: 600;
  min-width: 40px;
}
.col-quantity {
  width: 70%;
}
.varient-table .col-quantitybtn {
  text-align: right !important;
}
.col-quantitybtn button[disabled] {
  background: #3598dc;
  color: #fff;
}
.col-quantitybtn button {
  min-width: 100px;
  border-radius: 30px;
}
.buyer-want-list td.col-proprice {
  padding-left: 10px !important;
}
.back-btn {
  background: #000;
  border: none;
  border-radius: 5px;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 500;
}
.varient-table td:last-child {
  text-align: center;
}
.varient-table th {
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
}
.varient-table th,
.varient-table td {
  padding: 10px;
}

.style-table tr:nth-child(even) td {
  background-color: #f2f2f2;
}

.MuiAccordionSummary-content {
  margin: 0px !important;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .stleref {
    font-size: 11px !important;
    font-family: "Montserrat", sans-serif !important;
  }

  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .stleref {
    font-size: 11px !important;
    font-family: "Montserrat", sans-serif !important;
  }

  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .stleref {
    font-size: 11px !important;
    font-family: "Montserrat", sans-serif !important;
  }

  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.stleref {
  font-size: 11px !important;
  font-family: "Montserrat", sans-serif !important;
}

.table-responsive > .table-bordered {
  border: 0;
}

.shopping-cart {
  width: 1450px;
  height: auto;
  margin: 80px auto;
  background: #ffffff;
  box-shadow: 1px 2px 3px 0px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}

.title {
  height: 60px;
  border-bottom: 1px solid #e1e8ee;
  padding: 20px 30px;
  color: #5e6977;
  font-size: 18px;
  font-weight: 800;
}

.item {
  padding: 20px 30px;

  display: flex;
}

.item:nth-child(3) {
  border-top: 1px solid #e1e8ee;
  border-bottom: 1px solid #e1e8ee;
}

.buttons {
  position: relative;
  padding-top: 30px;
  margin-right: 60px;
}

.delete-btn,
.like-btn {
  display: inline-block;
  cursor: pointer;
}

.delete-btn {
  width: 18px;
  height: 17px;
  /* background: url(&amp;quot;delete-icn.svg&amp;quot;) no-repeat center; */
}

.like-btn {
  position: absolute;
  top: 9px;
  left: 15px;
  /* background: url('twitter-heart.png'); */
  width: 60px;
  height: 60px;
  background-size: 2900%;
  background-repeat: no-repeat;
}

.is-active {
  animation-name: animate;
  animation-duration: 0.8s;
  animation-iteration-count: 1;
  animation-timing-function: steps(28);
  animation-fill-mode: forwards;
}

@keyframes animate {
  0% {
    background-position: left;
  }

  50% {
    background-position: right;
  }

  100% {
    background-position: right;
  }
}

.description {
  padding-top: 10px;
  margin-right: 60px;
  width: auto;
}
.cart-value {
  text-align: center !important;
  margin: 10px 0px;
}

.description span {
  display: block;
  font-size: 14px;
  color: #43484d;
  font-weight: 400;
}

.description span:first-child {
  margin-bottom: 5px;
}

.description span:last-child {
  font-weight: 300;
  margin-top: 8px;
  color: #86939e;
}

.total-price {
  /* width: 83px; */
  padding-top: 27px;
  text-align: center;
  font-size: 16px;
  color: #43484d;
  font-weight: 300;
}

.thankYouPage p {
  color: #404f5e;
  font-family: "Montserrat", sans-serif;
  font-size: 25px;
  text-align: center;
  font-weight: 600;
  margin: 0;
  line-height: normal;
}
.description-cart p {
  display: none;
}

.thankYouPage i {
  color: #9abc66;
  font-size: 100px;
  line-height: 200px;
}

.thankYouPageCard {
  background: white;
  padding: 60px;
  border-radius: 8px;
  box-shadow: 0 2px 3px #c8d0d8;
  display: table;
  margin: 190px auto;
}

.swal2-backdrop-show {
  padding: 0 20px;
}
.swal2-popup {
  padding: 20px;
}

.icon-div {
  border-radius: 200px;
  height: 150px;
  width: 150px;
  background: #f8faf5;
  margin: 0 auto 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.thankYouPage h1 {
  color: #88b04b;
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 15px;
  text-align: center;
}

.saveWantList {
  margin-left: 20px;
}

.cancleBtn {
  margin-left: 20px !important;
}

.createWantLIstItems {
  margin-top: 400px;
}

.wantlist-table {
  width: 100% !important;
}

.profile-update-btn {
  margin-top: 20px;
}

.saveWantList {
  justify-content: end;
  margin-right: 25px;
}

.sub-total {
  text-align: left !important;
}

.total-weight {
  max-width: 500px;
}

.sub-check-total {
  justify-content: end !important;
}

.sub-price {
  text-align: left !important;
}

.item-btn {
  justify-content: end !important;
}

.sub-price {
  text-align: end !important;
}

.sub-quantity {
  margin-right: 25px;
}

.sub-price {
  margin-right: 25px;
}

.Product-wordwrap {
  width: 350px;
}

.item-head {
  text-align: left;
  font-size: 15px;
}

.button-items {
  display: flex;
}
.weightPrice {
  display: contents;
}

/* .total-product {
  display: contents;
} */

.item-subprice {
  display: flex;
}

.checkout-btns {
  display: flex;
}

.total-count {
  margin: 0px 15px;
}

.redeem-error {
  font-size: 10px;
  color: red;
  margin: 5px 0;
  line-height: 10px;
}

.MuiTypography-body1 td {
  word-break: normal;
}

.cart-btn {
  /* margin-right: 12px; */
  float: right;
}

.table-name {
  width: 50%;
}

.thankYouPageCard {
  text-align: center;
}

.scanner-btn {
  margin-top: 25px;
  padding: 10px;
}

.number-quantity {
  display: flex;
  align-items: center;
  justify-content: center;
}

.number-box {
  display: flex;
  align-items: center;
}

.number-box .plus {
  line-height: 18px;
}

.number-box .minus {
  line-height: 18px;
}

.number-quantity .plus {
  line-height: 14px;
  padding-left: 2px;
}

.number-quantity .minus {
  line-height: 14px;
  padding-left: 2px;
}

.quantity-btn {
  background-color: inherit;
  border: inherit;
  padding: 0;
  margin: 0;
}
.quantity input {
  margin: 0 6px !important;
}

.number {
  display: flex !important;
  justify-content: flex-start;
}

.quantity input {
  margin: 0;
}

.add-product-number {
  justify-content: left !important;
}

.minus-btns {
  padding-left: 0px;
}

.createBasket-btn {
  justify-content: center !important;
}

.qrcode {
  cursor: pointer;
}

.have-list {
  margin-top: 230px !important;
}

@import url("https://fonts.googleapis.com/css?family=Overpass");

/* * {
	box-sizing: border-box;
	font-family: "Overpass", sans-serif;
}

body {
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: "Overpass", sans-serif;
} */

.tags-input {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-height: 35px;
  width: 100%;
  padding: 0 8px;
  border: 1px solid rgb(214, 216, 218);
  border-radius: 6px;

  &:focus-within {
    border: 1px solid #2c2d2e;
  }
}

.tag {
  width: auto;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #212121;
  padding: 0 8px;
  font-size: 14px;
  list-style: none;
  border-radius: 6px;
  margin: 0 8px 0px 0;
  background: rgba(53, 152, 220, 0.13);

  .tag-title {
    margin-top: 3px;
  }

  .tag-close-icon {
    display: block;
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    font-size: 14px;
    margin-left: 8px;
    color: #0052cc;
    border-radius: 50%;
    background: #fff;
    cursor: pointer;
  }
}

.fa-search:before {
  content: "\f002";
  margin-right: 5px;
}

.form-controls {
  border-radius: 0;
  padding: 7px;
}

.fa-refresh:before {
  margin-right: 10px;
}

.descriptions {
  text-align: initial;
}

button.btn.btn-default.btn-dark.w-20.m-auto.px-3.my-2 {
  border-radius: 8px;
}

button.searchButtonflex.btn.btn-dark {
  margin-right: 10px;
}

.back-button {
  margin-left: 29%;
}

.tags-input-profile {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-height: 35px;
  width: 100%;
  /* border: 1px solid rgb(214, 216, 218);  */
  border-radius: 6px;
  margin-bottom: 10px;
}
.tags-input-profile li {
  margin-bottom: 10px;
}

.input-radio {
  margin-left: 0px;
  margin-top: 1px;
  display: flex;
}

.input-fieldset {
  display: flex;
}

.add-form-submit {
  width: 35%;
  margin-left: 30%;
}
.add-form-submit.previewButton {
  width: 35%;
  margin-left: 0;
}
.input-text-box {
  margin-left: 10px;
  margin-top: 1px;
  border-radius: 5px;
  border: solid 1px #dbdbdb;
  text-align: left;
  padding: 8px 12px;
}
.radio-type {
  margin-top: 5px;
}
.radio-type.input-field input {
  margin-right: 5px;
}
.input-fieldset label {
  margin-right: 5px;
}
.input-radio > .radio-type {
  margin-right: 10px;
  display: flex;
  align-items: center;
}
.input-radio > .radio-type input {
  margin-right: 5px;
}

.password {
  display: none;
}
.description {
  display: block;
  margin-top: 5px;
  font-size: 12px;
  color: #888;
}
.address {
  margin-top: 10px;
  margin-bottom: 40px;
  text-align: right;
  font-weight: 600;
  font-size: 13px;
}
.order-heading {
  margin-top: 10px;
}
.cat-dropdown .dropdown {
  width: 100%;
}

.btns {
  font-size: 16px;
  color: #fff;
  background-color: #000;
  font-weight: 500;
  max-width: 170px;
  /* padding: 10px !important; */
}
.pro-image img {
  max-width: 100px;
  max-height: 150px;
  object-fit: cover;
}
.swal2-image {
  object-fit: cover;
  max-height: 350px;
}

.company-logo {
  display: flex;
  align-items: center;
}
.company-logo img {
  max-width: 100px;
}
.company-logo .page-title {
  margin: 0;
  padding: 0 0 0 15px;
}
.company-logo .page-title h2 {
  color: #000;
}

.company-logo {
  display: flex;
  align-items: center;
}
.company-logo img {
  max-width: 100px;
}
.company-logo .page-title {
  margin: 0;
  padding: 0 0 0 15px;
}
.company-logo .page-title h2 {
  color: #000;
}
.style-logo {
  max-width: 350px;
}
.productitem .status {
  font-size: 14px !important;
  font-weight: 500;
}

.productitem {
  background: #fff;
  padding: 15px;
  border-radius: 15px;
  margin-bottom: 15px;
}
.productitem h2 {
  font-size: 18px;
  font-weight: 600;
}
.productitem .edit-delete-btn {
  display: flex;
  font-size: 20px;
  align-items: center;
}
.productitem .edit-delete-btn button {
  margin-left: 10px;
}
.productitem .edit-delete-btn button svg {
  height: 17px;
}
.itemheading {
  display: flex;
  justify-content: space-between;
}
.totalquantity {
  font-size: 14px;
  color: #000;
  font-weight: 600;
}
.productitem .MuiAccordion-root {
  box-shadow: none;
  border: none;
}
.productitem .MuiAccordion-root:before {
  display: none;
}
.productitem .MuiSvgIcon-root {
  width: 25px;
  height: 25px;
  fill: #000;
}
.productitem .MuiIconButton-root {
  padding: 0;
}
.productitem .MuiAccordionSummary-root.Mui-expanded {
  min-height: 30px;
}
.productitem .MuiAccordionDetails-root {
  padding: 0;
}
.productitem .MuiAccordionSummary-root {
  padding: 0;
  min-height: auto;
}
.productitem .MuiIconButton-edgeEnd {
  margin-right: -6px;
}
.history-card,
.stock-table {
  background-color: #3598dc14;
}

.MuiPaper-elevation1 {
  box-shadow: none !important;
}
.havelist-card.card {
  border-radius: 10px;
}

.have-card .heading h4 {
  color: #272727;
  font-weight: 700;
}
/* .havelist-card .MuiCollapse-wrapper{background-color: #3598dc14;} */
.havelist-card .MuiCollapse-wrapper .MuiAccordionDetails-root {
  padding: 16px 10px;
}
.have-qr {
  width: 135px;
}
.redeem-sec .have-qr {
  margin-left: auto;
}
.product-rules div label {
  min-width: 290px;
}
.number-quantity,
.quantity-box {
  border: 1px solid;
  background-color: #fff;
  border-radius: 30px;
  overflow: hidden;
}
.number-quantity .quantity-btn,
.quantity-box .quantity-btn {
  border: none;
}
.havelist-card {
  margin-bottom: 15px;
}
.MuiAccordionDetails-root {
  /* background: #3598dc14; */
  margin: 20px 0px;
}
.MuiAccordionSummary-content .page-title {
  margin: inherit !important;
}
.swal2-styled.swal2-confirm {
  background-color: #272727 !important;
}
.swal2-styled.swal2-confirm:focus {
  box-shadow: none !important;
}

.quantity-box,
.number-quantity {
  display: flex;
  padding: 1px 7px;
}
.quantity-btn svg,
.number-quantity svg {
  max-width: 15px;
}
.quantity-box input {
  font-size: 14px;
}
.subitems {
  margin-top: 15px;
}
.subitems:first-child {
  margin-top: inherit;
}
.quantity-box input:focus-visible,
.number-quantity input:focus-visible {
  outline: none !important;
}

.note svg {
  margin-right: 8px;
}
.note p {
  margin: 0;
  color: #212121 !important;
}

.note svg path {
  fill: #3598dc;
}
.card.havelist-card .page-title h2,
.redeem-history .page-title h2 {
  font-size: 15px;
  font-weight: 600;
  display: flex;
  align-items: center;
}
.card.havelist-card .page-title h2 span,
.redeem-history .page-title h2 span,
.right-panel .page-title h2 span {
  font-size: 19px;
  color: #3598dc;
  margin-left: 7px;
}
.card.havelist-card .page-title,
.redeem-history .page-title {
  display: flex;
  align-items: center;
}
.more-btn {
  padding: 0px 11px;
  border-radius: 5px;
  border: none;
  height: 40px;
  line-height: 40px;
}
.MuiAccordionSummary-root {
  padding: 0px !important;
}
input:focus {
  box-shadow: none !important;
}
.scanner-btn {
  max-width: 200px;
}
.product-quantity {
  display: flex;
  justify-content: center;
}
.quantity-cart {
  border: 1px solid;
  padding: 1px 7px;
  display: flex;
  align-items: center;
  border-radius: 18px;
}
.quantity-cart .quantity-btn {
  border: none;
}
.quantity-cart {
  max-width: 103px;
  margin: auto;
}
.product-list {
  align-items: start !important;
}
.descriptions {
  width: 85%;
}
.cart-btn i,
.action-btn i {
  color: #212121 !important;
}
.order-eye-btn {
  display: flex;
  justify-content: flex-start;
  padding: 9px 10px;
}
.loader-BG-cart {
  display: flex;
  gap: 15px;
}
.modal-footer .cart-btn {
  display: flex;
  gap: 15px;
  justify-content: center;
}
/* .cart-btn{width: 120px; text-align: center;} */
.cart-btn {
  text-align: center;
}
.btn:hover {
  background-color: #212121;
  color: #fff;
}
.btn:active {
  background-color: #212121;
  color: #fff;
}
.btn:focus {
  box-shadow: none;
}
.btn:active:focus {
  box-shadow: none;
}
.detail-btn {
  padding: 9px 20px;
  margin-top: 15px;
}
.cart-btn img {
  min-width: 30px;
  height: 30px;
}

.new-tag {
  height: 45px;
  overflow-x: auto;
}
.tag {
  margin-bottom: 5px;
}

.tag-close-icon {
  /* width: 43px !important; */
  line-height: 14px !important;
}

.navbar-toggler:focus {
  box-shadow: none;
}
.seller-listing-p {
  display: flex;
}

.seller-listing-p .basketbtn .cart-btn img {
  margin-top: 0px;
}

.basket-view-lg {
  display: flex;
  gap: 12px;
  justify-content: flex-end;
}
.total-order-price {
  float: right;
  margin-top: -34px;
}
.login-forget {
  display: inline-flex;
}
.forget {
  margin-right: 50px;
}

.seller_detail .quantity .number {
  justify-content: center;
}
.add-seller-products-table tr td {
  vertical-align: top;
}
.css-1pxa9xg-MuiAlert-message {
  font-family: "Montserrat", sans-serif !important;
}

.swal2-close {
  position: absolute;
  right: 0;
  top: 0;
  display: block !important;
  line-height: 25px;
}
.btn-addstore {
  max-width: 400px;
  margin: auto;
  font-family: "Montserrat", sans-serif !important;
}

.swal2-title p {
  font-size: 15px;
}
.swal2-title h5 {
  font-size: 17px;
}

.swal2-html-container th {
  font-size: 14px;
}
.swal2-html-container {
  margin: 1em 0 0.3em !important;
}
.filter-btns button {
  margin-right: 5px;
}
.filter-btns {
  margin-top: 30px;
}
.filter-btns button {
  background: #fff;
  color: #000;
}
.filter-btns .btns {
  background: #c8c8c8;
  color: #000;
}
.order-total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 35px;
}
.order-total h5 {
  font-size: 18px;
}
.qty-button {
  display: flex;
  justify-content: space-between;
}
.activation-btn .btn-transparent {
  border: none;
  background: none;
}
.item-table .err-table-msg {
  position: absolute;
  bottom: -5px;
}
.item-table td {
  position: relative;
}

@media screen and (max-width: 767px) {
  .btn-productlist {
    margin: 15px 0 0;
    padding: 0 0px;
  }
  .filter-btns {
    margin-top: 0px;
  }
  .sm-d-block {
    display: block !important;
  }

  .stleref {
    font-size: 11px !important;
    font-family: "Montserrat", sans-serif !important;
  }
  .btn-productlist button {
    margin: 0 0 15px;
    width: 100%;
  }
  .small-block {
    display: block !important;
  }
  .varient-table .itemName {
    margin-bottom: 0;
    min-width: 112px;
  }
  .quantity-box input {
    max-width: 30px;
  }
  .quantity input {
    height: 30px;
  }
}
